/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: webapp-contrib
 * Version: 7.1.2
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
window.LOCALE_BO = {
  supprimer: "Delete",
  annuler: "Cancel",
  ajouter: "Add",
  creer: "Creation",
  editer: "Cancel",
  valider: "Validate",
  archiver: "Archive",
  consulter: "Read",
  modifier: "Edit",
  copy: "Copy",
  nouvelleFenetre: "(New Window)",
  selectionner: "Select",
  confirmer: "Confirm",
  confirmerQuitter: "All unsaved modifications will be lost, do you want to continue ?",
  fermer: "No",
  open : "Open",
  close: "Close",
  erreur: "Error",
  ok: "Yes",
  confirmQuitterPage: "Are you sure you want to leave this page&nbsp;?",
  confirmSupprFiche: "The deletion of this content can impact some sections' and contents' URLs. Are you sure you want to delete this content&nbsp;?",
  selectionnerUnELement: "Please select at least one item before starting an action.",
  confirmSupprFiches: "The deletion of these contents can impact some sections' and contents' URLs. Are you sure you want to delete these contents&nbsp;?",
  confirmSupprUtilisateur: "Are you sure you want to delete this user&nbsp;?",
  confirmSupprUtilisateurs: "Are you sure you want to delete these users&nbsp;?",
  confirmSupprGroupe: "Are you sure you want to delete this group&nbsp;?",
  confirmSupprRubrique: "Are you sure you want to delete the section &quot;{0}&quot; with its full arborescence and contents ? Careful, only the contents and theirs plugin will be deleted.<br/>This operation can't be undone.",
  confirmSupprLibelle: "Are you sure you want to delete this label&nbsp;?",
  confirmArchFiches: "The archiving of these contents can impact some sections' and contents' URLs. Are you sure you want to archive these contents&nbsp;?<br(Only online contents will be archived.)",
  lExtension: "The extension",
  leModule: "The module",
  patienter: "<br/> Please wait ...",
  voirEnLigne: "View online",
  actions: "Actions",
  erreurRecherche: "An error occurred while calculating your search results.",
  erreurActionMasse: "An error occurred while processing your action.",
  confirmationActionMasseSuppr: "The selected contents have been deleted.",
  confirmationActionMasseArchi: "The published contents have been archived.",
  confirmationActionMasseUtilisateur: "The selected users have been deleted.",
  activer: "Activate",
  desactiver: "Disable",
  selectall: "Select all",
  selectnone: "Select none",
  sectionsImpacts: "The modification of the section will impact %d URL(s), would you like to continue ?",
  confirmSupprUrlRedirect: "Are you sure you want to delete this redirection url?",
  pagesImpacts: "The modification of the page will impact %d URL(s), would you like to continue ?",
  siteImpacts: "Your changes could impact a large number of URLs, would you like to continue ?",
  headPageImpact: "This content is a section's homepage. Archiving this page will impact the section's URL and its contents URLS. Would you like to continue ?",
  headPageImpactDelete: "This content is a section's homepage. Deleting this page will impact the section's URL and its contents URLS. Would you like to continue ?",
  mandatorySection: "The area Section is mandatory",
  previewError: "Preview error : {errMsg}",
  unexpectedPreviewError: "Unexpected preview error, server response: {errMsg}",

  // Etats
  enCoursSuppression: "is being deleted.",
  enCoursRechargement: "Reload in progress",
  enSuccesSuppression: "Delete was successful",
  enErreurSuppression: "An error occurred while deleting",
  enCoursdActivation: "is being activated",
  enSuccesActivation: "Activation was successful",
  enErreurActivation: "An error occurred during activation",
  enCoursdeDesactivation: "is being disabled.",
  enSuccesDesactivation: "Deactivation was successful",
  enErreurDesactivation: "An error occurred while deactivating",
  enSuccesRechargement: "Reload complete",
  enErreurRechargement: "An error occurred while reloading",
  enSucces: "Success",
  enErreur: "Error",
  jobSiteInProgress: "Process in progress",

  parcourir: "Click on Browse",

  select: {
    undefined: "Select from the list"
  },

  libelle: {
    inconnu: "Unknown label"
  },

  viewTools: {
    switchView: "Switch view",
    treeView: "Tree structure",
    tableView: "Table"
  },

  kselect: {
    mono: {
      select: "Select",
      clear: "Clear"
    },
    multi: {
      parentLabel: "Available item(s)",
      compositionLabel: "Selected item(s)"
    }
  },

  // Validations
  validationMessages: {
    caracteres_autorises: "allowed characters",
    ksupDate: "Please provide a valid date",
    ksupPhone: "Please provide a valid phone number",
    ksupUrl: "Please provide a valid url",
    ckeditor: "{0} characters allowed.",
    pwdVerdicts: ["Low", "Normal", "Average", "Strong", "Very strong"],
    pwdErrorMessages: {
      password_too_short: "Too short",
      email_as_password: "Do not use your email as a password",
      same_as_username: "Your password cannot contain your login ID",
      two_character_classes: "Vary character case",
      repeated_character: "Too many repetitions",
      sequence_found: "Your password contains sequences"
    }
  },

  // Services
  services: {
    arbre: {
      indisponible: "Tree manager service not reachable. No action will be performed.",
      noSearchResult: "No search result",
      confirmerSuppression: "Are you sure you want to delete this item \{0\}?",
      confirmerDrop: "The modification of the section could impact a large number of URLs, would you like to continue ?"
    },
    auto_complete: {
      search: "Search in progress...",
      noresult: "No results"
    }
  },

  // Popins
  popin: {
    title: {
      generique: "Select an item",
      structure: {
        mono: "Select an institution",
        multi: "Select institutions"
      },
      rubrique: {
        mono: "Select a section",
        multi: "Select sections"
      },
      groupe: {
        mono: "Select a group",
        multi: "Select groups"
      },
      utilisateur: "Select a user",
      objet: "Select a content",
      pagelibre: "",
      photo: "Select a picture",
      fichier: "Select a file"
    },
    errorFile: "The deletion of this message will delete the error backup as well. Are you sure you want to delete this message ?"
  },

  //Médias
  media: {
    actions: {
      "delete": {
        confirm: "Are you sure you want to delete this resource ?"
      }
    },
    player: {
      mute: "Enable / Disable Sound",
      playPause: "Play / Pause",
      fullscreen: "Full Screen",
      tracks: "Subtitles",
      postRoll: "Close"
    }
  },

  //Layout
  layout: {
    confirm: {
      title: "Switch layout ?",
      "continue": "Continue",
      cancel: "Cancel"
    }
  },

  supervision: {
    settings: {
      title: "Processing setting"
    }
  },

  userFront: {
    title: "Reject"
  },
  validation : {
    titre : "Selecting the approval level",
    niveauAuto : "Your form will automatically switch to the following state:",
    delegation : "You can delegate your validation rights to one of the following levels:"
  },
  // CKEditor
  ckeditor: {
    link: {
      section: {
        contextual: "Contextual"
      }
    },
    anchor: {
      alert: "The name must start with a letter and can only contains following characters : A-Z, a-z, 0-9, :-_."
    },
    notifications: {
      spellcheker: "Spell-checker is available through <kbd title=&quot;Controle&quot;>Ctrl</kbd> + right click"
    },
    plugins: {
      listeFiche: {
        title: "Contents list",
        insert: "Insert a list"
      },
      link: {
        title: "Insert a link",
        anchor: {
          tip: {
            alert: "No anchors was found in the current page."
          }
        }
      },
      kflipbook: {
        title: "Insert a Flipbook",
        menu: "Flipbook properties"
      },
      kimage: {
        title: "Insert a picture",
        menu: "Image properties",
        erreur_legende: "You must enter a legend."
      },
      kvideo: {
        title: "Insert a video",
        menu: "Video properties"
      },
      kaudio: {
        title: "Insert audio",
        menu: "Audio properties"
      },
      kflash: {
        title: "Insert a Flash animation",
        menu: "Flash animation properties"
      },
      kpdfviewer: {
        title: "",
        menu: ""
      },
      ktag: {
        title: "Tags",
        menu: "Tag properties"
      }
    }
  },
  tree:{
    selectChildren: "Select children",
    unselectChildren: "Unselect children",
    add: "Add",
    copy: "Copy",
    delete: "Delete",
    edit: "Edit"
  }
};
